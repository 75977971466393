import { useEffect } from "react";
import crypto from "crypto-es";
import CryptoES from "crypto-es";

import { SetterOrUpdater } from "recoil";
import { NavigateFunction } from "react-router-dom";

// import Base64 from "crypto-js/enc-base64";

import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export const setCookie = (name: string, value: string, options?: any) => {
  return cookies.set(name, value, { ...options });
}

export const getCookie = (name: string) => {
  return cookies.get(name);
}


export const UtctoLocaleFormmatter = (value: string) => {
  const date = new Date(value);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return year + "-" + (month >= 10 ? month : "0" + month) + "-" + (day >= 10 ? day : "0" + day);
};

export function makeHyphenInput(data: any, type: any) {
  const value = data.target.value.replace(/\D+/g, "");
  let result;



  if (type === "phone") {
    const numberLength = 11;
    result = "";
    for (let i = 0; i < value.length && i < numberLength; i++) {
      switch (i) {
        case 3:
          result += "-";
          break;
        case 7:
          result += "-";
          break;

        default:
          break;
      }
      result += value[i];
    }
  } else if (type === "paycardNo") {
    const numberLength = 16;
    result = "";
    for (let i = 0; i < value.length && i < numberLength; i++) {
      switch (i) {
        case 4:
          result += "-";
          break;
        case 8:
          result += "-";
          break;
        case 12:
          result += "-";
          break;
        default:
          break;
      }
      result += value[i];
    }
  } else if (type === "paycardExp") {
    const numberLength = 4;
    result = "";
    for (let i = 0; i < value.length && i < numberLength; i++) {
      switch (i) {
        case 2:
          result += "-";
          break;
        default:
          break;
      }
      result += value[i];
    }
  }

  data.target.value = result;
  return data.target.value;
}

export const paycardHypen = (cardNo: string | undefined) => {
  if (cardNo == undefined) {
    return cardNo;
  }
  const numberLength = 16;
  var result = "";
  for (let i = 0; i < cardNo.length && i < numberLength; i++) {
    switch (i) {
      case 4:
        result += "-";
        break;
      case 8:
        result += "-";
        break;
      case 12:
        result += "-";
        break;
      default:
        break;
    }
    result += cardNo[i];
  }
  return result
}

export function useOutsideAlerter(ref: any, setShowState: any) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowState(false);
        // alert("You clicked outside of me!");
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
}

export const formatISODateFormmatter = (date: Date, bool: 'start' | 'end') => {
  let newDate = new Date(date!);
  newDate.setHours(newDate.getHours() - newDate.getTimezoneOffset() / 60);
  if (bool === 'end') {
    newDate.setUTCHours(0, 0, 0, 0);
    newDate.setDate(newDate.getDate() + 1);
  }
  if (newDate !== undefined) {
    let formattedDate = newDate.getUTCFullYear() + '-' +
      ('0' + (newDate.getUTCMonth() + 1)).slice(-2) + '-' +
      ('0' + newDate.getUTCDate()).slice(-2) + 'T' +
      ('0' + newDate.getUTCHours()).slice(-2) + ':' +
      ('0' + newDate.getUTCMinutes()).slice(-2) + ':' +
      ('0' + newDate.getUTCSeconds()).slice(-2) + '.' +
      ('00' + newDate.getUTCMilliseconds()).slice(-3) + 'Z';

    return formattedDate
  } else {
    return undefined
  }
}

export function formatDate(dateString: any) {
  if (dateString === undefined) {
    return ''
  }
  const date = new Date(dateString);
  const year = date.getFullYear();
  let month = (date.getMonth() + 1).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export const UtctoLocaleDateTimeFormmatter = (utcDateTime: string) => {
  if (utcDateTime === undefined) {
    return undefined;
  }
  const date = new Date(utcDateTime);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  return (
    year +
    "-" +
    (month >= 10 ? month : "0" + month) +
    "-" +
    (day >= 10 ? day : "0" + day) +
    " " +
    (hour >= 10 ? hour : "0" + hour) +
    ":" +
    (minute >= 10 ? minute : "0" + minute) +
    ":" +
    (second >= 10 ? second : "0" + second)
  );
};

export const makeMoid = () => {
  let result = "billingKey" + GetMoidDate() + randomStr(20);
  return result;
};
export const randomStr = (length: number) => {
  const characters = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const charactersLength = characters.length;
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters[Math.floor(Math.random() * (charactersLength - 1))];
  }

  return result;
};
const GetMoidDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
  const day = date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`;
  return `${year}${month}${day}`;
};

export const GetSmartroDate = (isSecond: boolean) => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
  const day = date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`;

  const hour = date.getHours() >= 10 ? date.getHours() : `0${date.getHours()}`;
  const minute = date.getMinutes() >= 10 ? date.getMinutes() : `0${date.getMinutes()}`;
  const second = date.getSeconds() >= 10 ? date.getSeconds() : `0${date.getSeconds()}`;
  if (isSecond) {
    return `${year}${month}${day}${hour}${minute}${second}`;
  } else {
    return `${year}${month}${day}${hour}${minute}`;
  }
};

export const makePayEncryptData = (edidate: string, amt: string) => {
  const hashtext = crypto.SHA256(edidate + process.env.REACT_APP_SMARTRO_QR_MID + amt + process.env.REACT_APP_SMARTRO_QR_MERCAHNT);
  return crypto.enc.Base64.stringify(hashtext);
};

export function parseJwt(token: string) {
  // var base64 = token.replace(/-/g, "+").replace(/_/g, "/");
  var base64 = token;
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}

export function getUserIdFromToken() {
  const token = sessionStorage.getItem("accessToken");
  if (!token) {
    return null;
  }
  let payload = parseJwt(token.split(".")[1]);

  return payload.userId;
}

export function logoutHandler(setlogin: SetterOrUpdater<boolean>, navigate: NavigateFunction) {
  navigate("/");
  setlogin(false);
  sessionStorage.clear();
}

export function navLogoutHandler(setlogin: SetterOrUpdater<boolean>, clickHandler: any) {
  setlogin(false);
  clickHandler();
  sessionStorage.clear();
}

export const replaceCardSttus = (value: string) => {
  switch (value) {
    case "NONE":
      return "알수없음";
    case "READY":
      return "정상";
    case "CHARGING":
      return "충전진행중";
    case "STOP":
      return "정지";
    case "LOSS":
      return "분실";
    case "BROKEN":
      return "파손";
    case "WITHDRAWAL":
      return "탈퇴";
    case "EXPIRE":
      return "만료";
    default:
      break;
  }
};

export const checkKeyDown = (e: any) => {
  if (e.key === "Enter") e.preventDefault();
};


export const todayNoFoarmat = () => {
  let today = new Date();
  let result = "";
  result += today.getFullYear();
  result += today.getMonth() + 1 >= 10 ? (today.getMonth() + 1) : "0" + (today.getMonth() + 1);
  result += today.getDate() >= 10 ? today.getDate() : "0" + today.getDate();
  return result;
};



export const TodayFormatYYYYMMDDHHmmssSSS = () => {
  const date = new Date();

  const year = date.getFullYear();

  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hour = date.getHours().toString().padStart(2, '0');
  const minute = date.getMinutes().toString().padStart(2, '0');
  const second = date.getSeconds().toString().padStart(2, '0');

  const millisecond = date.getMilliseconds();
  // 무조건 String 으로 반환해야 함.
  return `${year}${month}${day}${hour}${minute}${second}${millisecond}`;
}



export const ediDateNoFormat = () => {
  let today = new Date();
  let result = "";
  result += today.getFullYear();
  result += today.getMonth() + 1 >= 10 ? (today.getMonth() + 1) : "0" + (today.getMonth() + 1);
  result += today.getDate() >= 10 ? today.getDate() : "0" + today.getDate();
  result += today.getHours() >= 10 ? today.getHours() : "0" + today.getHours();
  result += today.getMinutes() >= 10 ? today.getMinutes() : "0" + today.getMinutes();
  result += ('0' + today.getSeconds()).slice(-2);
  return result;
};

export const makeEncryptData = (edidate: any, moid: any) => {
  // const hash = crypto.createHmac('sha256', edidate + process.env.REACT_APP_SMARTRO_MID + moid + "SMARTRO!@#", secret)
  // crypto.
  const hashtext = (crypto.SHA256(edidate + process.env.REACT_APP_SMARTRO_MID + moid + "SMARTRO!@#"));
  return crypto.enc.Base64.stringify(hashtext);
}

export const addCommas = (num: number): string => {
  if (!num) {
    return "0";
  }
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export async function getDominantColor(imgSrc: string) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = imgSrc;
    img.onload = function () {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = 100;
      canvas.height = 100;
      ctx?.drawImage(img, 0, 0, 100, 100);
      const imageData = ctx?.getImageData(0, 0, canvas.width, canvas.height);
      const pixels = imageData?.data;
      const colorCounts: any = {};
      for (let i = 0; i < pixels!.length; i += 4) {
        const alpha = (pixels![i + 3] / 255).toFixed(1);
        const color = `rgba(${pixels![i]}, ${pixels![i + 1]}, ${pixels![i + 2]}, ${alpha})`;
        if (alpha == '0.0') {
          continue;
        }
        colorCounts[color] = (colorCounts[color] || 0) + 1;
      }
      let dominantColor;
      let maxCount = 0;
      for (const color in colorCounts) {
        if (colorCounts[color] > maxCount) {
          maxCount = colorCounts[color];
          dominantColor = color;
        }
      }
      resolve(dominantColor);
    };
    img.onerror = reject;
  });
}

export function roundToTwoDecimalPlaces(number: number) {
  return Math.round(number * 100) / 100;
}