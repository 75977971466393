/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { DefaultValue, RecoilState, selector, useRecoilState, useRecoilStateLoadable } from "recoil";
import { useLayoutEffect, useState, useMemo } from "react";
import { loadingState } from "../recoil/atoms";
import _ from "lodash";

function useFetch<T>({
  recoilSelector,
  query,
  setQuery,
}: {
  recoilSelector: RecoilState<T> | null;
  query?: any;
  setQuery?: React.Dispatch<any>;
}): T | undefined {
  const dummySelector = useMemo(() =>
    selector<T>({
      key: `dummySelector_${Math.random()}`,
      get: () => undefined as unknown as T,
      set: (_, newValue) => { },
    }),
    []);

  const [getData, setData] = useRecoilStateLoadable<T>(recoilSelector || dummySelector);
  const [isLoading, setLoading] = useRecoilState(loadingState);
  const [_query, _setQuery] = useState<any>();

  useLayoutEffect(() => {
    if (!recoilSelector) return;

    if (!_.isEqual(_query, query)) {
      // 이전 상태와 변경된 상태가 다를 경우에만 setData 호출
      setLoading(true);
      setData(query);
      _setQuery(query);
    }
  }, [query, recoilSelector]);

  useLayoutEffect(() => {
    if (!recoilSelector) return;

    if (getData.state !== "loading") {
      setLoading(false);
    }
  }, [getData.state]);

  if (!recoilSelector) return undefined;
  if (getData.state !== "loading") {
    return getData.contents;
  }
}

export default useFetch;
