/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Link, useParams } from "react-router-dom";
import { Blank, Div, EvBorderButton, Font, PageLayout } from "../../styles/commonStyle";
import { cardTitleColor } from "../../styles/theme";
import { RESULT, ResponseModel } from "../../model/response";
import { Notice } from "../../model/notice";
import { useEffect, useLayoutEffect, useState } from "react";
import EvModal from "../../components/modal";
import { DetailContent, ListContent } from "../../components/listTile/style";
import { HeadDate, HeadTitle, ListHead } from "./style";
import { useRecoilState, useResetRecoilState } from "recoil";
import useFetch from "../../hooks/useFetch";
import { noticeDetailSelector } from "../../recoil/variableAtom";
import { UtctoLocaleFormmatter } from "../../util";
import Loading from "../../components/loading/loading";
import { loadingState, modalState, noticeDetailState, pageState, searchState } from "../../recoil/atoms";
const parse = require("html-react-parser");

function NoticeDetailPage() {
  const [isLoading, setLoading] = useRecoilState(loadingState);
  let { id } = useParams();
  const [currentPage, setPage] = useRecoilState(pageState);
  const [getSearch, setSearch] = useRecoilState(searchState);
  const [isModalOpen, setModalOpen] = useRecoilState(modalState);
  const [notice, setNotice] = useState<Notice>();
  const resetState = useResetRecoilState(noticeDetailState);
  const response: ResponseModel<Notice> | undefined = useFetch<ResponseModel<Notice>>({
    recoilSelector: noticeDetailSelector,
    query: id,
  });

  useLayoutEffect(() => {
    if (response?.result === RESULT.FAIL) {
      setModalOpen(true);
    }
    if (response?.result === RESULT.OK) {
      setNotice(response.data);
      resetState();
    }
  }, [response]);

  useEffect(() => {
    if (!notice) {
      return;
    }
    let content = document.getElementById("detailContent");
    let tableList = content!.querySelectorAll("table");
    for (let i = 0; i < tableList.length; i++) {
      tableList[i].style.border = `${tableList[i].getAttribute("border")}px solid ${tableList[i].style.borderColor ?? "black"}`;
      if (tableList[i].getAttribute("cellspacing")) {
        tableList[i].style.borderCollapse = "separate";
        tableList[i].style.borderSpacing = `${tableList[i].getAttribute("cellspacing")}px`;
      }

      let trList = tableList[i].querySelectorAll("tr");
      for (let j = 0; j < trList.length; j++) {
        trList[j].style.border = `${tableList[i].getAttribute("border")}px solid ${trList[j].style.borderColor ?? "black"}`;
      }
      let tdList = tableList[i].querySelectorAll("td");
      for (let j = 0; j < tdList.length; j++) {
        tdList[j].style.border = `${tableList[i].getAttribute("border")}px solid ${tdList[j].style.borderColor ?? "black"}`;
      }
    }
  }, [notice]);

  return (
    <PageLayout>
      <Font fontSize="1.75rem" fontWeight="500" color={cardTitleColor} changeFontSize="20px">
        공지사항
      </Font>
      <Blank height="24px" />
      <ListHead>
        <HeadTitle className="px-[12px]">{notice?.sj}</HeadTitle>
        <HeadDate className="px-[12px]">{UtctoLocaleFormmatter(notice?.reg_dt!)}</HeadDate>
      </ListHead>
      {notice ? (
        <DetailContent>
          {/* <Div dangerouslySetInnerHTML={{ __html: notice?.cn! }} id="detailContent"></Div> */}
          <Div className="w-full h-full" id="detailContent">
            {parse(notice?.cn)}
          </Div>
        </DetailContent>
      ) : (
        <ListContent>
          <Loading isLoading={true} cssStyle="w-full min-h-[500px] flex bg-[#FFF]/60 items-center" />
        </ListContent>
      )}
      <Blank height="12px" />
      <Link to={getSearch ? `/notice?currentPage=${currentPage}&search=${getSearch}` : `/notice?currentPage=${currentPage}`}>
        <EvBorderButton color="#808184" borderColor="#bbbdbf">
          목록으로
        </EvBorderButton>
      </Link>
      <Blank height="24px" />
      <EvModal msg={response?.msg!} />
    </PageLayout>
  );
}
export default NoticeDetailPage;
