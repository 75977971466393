/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Blank, Div, Font, Form, PageLayout } from "../../styles/commonStyle";
import { cardTitleColor, primaryColor, spanBlueColor } from "../../styles/theme";
import { Arrow, ArrowBox, PageMoveBtn, Tab } from "./style";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import arrowNextActive from "../../assets/images/arrow-next-active.svg";
import arrowPrev from "../../assets/images/arrow-prev.svg";
import StipulationList from "./components/stipulation";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { memberRegisterValidation } from "../../validation";
import { useRecoilState } from "recoil";
import { carCompanySelector, userRegistSelector } from "../../recoil/variableAtom";
import { RESULT, ResponseModel } from "../../model/response";
import MemberRegisterForm from "./memberRegistForm";
import useFetch from "../../hooks/useFetch";
import EvModal from "../../components/modal";
import usePost from "../../hooks/usePost";
import { checkKeyDown, todayNoFoarmat } from "../../util";
import Loading from "../../components/loading/loading";
import { errorState, loadingState, modalState, modalTypeState, smatroReigistBillingState, userRegistIngState, userRegistState } from "../../recoil/atoms";
import React from "react";

function RegisterMemberPage() {
  const [formNum, setFormNum] = useState(1);
  const [isSubmitOk, setSubmitOk] = useState(false);
  const [stipulationAgree, setStipulationAgree] = useState([false, false, false]);
  const [isModalOpen, setModalOpen] = useRecoilState(modalState);
  const [getCarCompanys, setCarCompanys] = useState<string[]>();
  const [isLoading, setLoading] = useRecoilState(loadingState);
  const [errMsg, setErrMsg] = useRecoilState(errorState);
  const [modalType, setModalType] = useRecoilState(modalTypeState);
  const [getBody, setBody] = useState<any>();
  const getCarCompanysApi: ResponseModel<any> = useFetch({
    recoilSelector: carCompanySelector,
  });
  const response: ResponseModel<any> = usePost({
    recoilSelector: userRegistSelector,
    data: getBody,
    recoilState: userRegistState,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const [userRegistIng, setUserRegistIng] = useRecoilState(userRegistIngState);
  const [smatroReigistBilling, setSmatroReigistBilling] = useRecoilState(smatroReigistBillingState);

  const {
    register: memberRegister,
    handleSubmit: memberHandleSubmit,
    formState: { errors: memberErrors },
    setValue: memberSetValue,
    getValues: memberGetValues,
  } = useForm({
    resolver: yupResolver(memberRegisterValidation),
    reValidateMode: "onBlur",
    mode: "onSubmit",
  });

  async function onRegistSubmit(data: FieldValues) {
    if (formNum === 2) {
      let formData = new FormData();
      formData.append("ty", "MEMBER");
      formData.append("lgnId", data.lgnId);
      formData.append("nm", data.nm);
      formData.append("phone", data.phone);
      formData.append("email", data.email);
      formData.append("lgnPassword", data.password);
      formData.append("adres", data.adres);
      formData.append("rdnmadr", data.rdnmadr);
      formData.append("adresDetail", data.adres_detail);
      formData.append("zipCode", data.zipCode);
      formData.append("paycardNo", data.paycardNo);
      formData.append("paycardExp", data.paycardExp);
      formData.append("paycardBirth", data.paycardBirth);
      formData.append("paycardPw", data.paycardPw);
      formData.append("carNo", data.carNo);
      formData.append("carModelId", data.carModelId.value.toString());
      formData.append("smsRcptnAgreYn", data.smsRcptnAgreYn ? "Y" : "N");
      formData.append("emlRcptnAgreYn", data.emlRcptnAgreYn ? "Y" : "N");
      setBody(formData);
    }
  }
  useEffect(() => {
    if (response?.result === RESULT.OK) {
      navigate("/register/complete", { replace: true });
      setSmatroReigistBilling(undefined);
    }
    if (response?.result === RESULT.FAIL) {
      setErrMsg(response.msg!);
      setModalOpen(true);
      setModalType("inputError");
    }
  }, [response]);

  const getCarCompanyCall = () => {
    let res = getCarCompanysApi;
    if (getCarCompanysApi === undefined) return;
    if (res.result === RESULT.OK) {
      let tempCompanyList: string[] = ["선택없음", ...res.data.map((value: any) => value)];
      setCarCompanys(tempCompanyList);
    } else {
      setErrMsg(res.msg!);
      setModalOpen(true);
    }
  };


  useEffect(() => {
    getCarCompanyCall();
  }, [getCarCompanysApi])

  useEffect(() => {
    window.history.pushState("", '', `/register?fn=${formNum}`)
    if (formNum === 2) {
      getCarCompanyCall();
    }
  }, [formNum]);

  const gotoPrev = () => {
    if (formNum === 1) {
      navigate(-1);
    } else if (formNum === 2) {
      setSubmitOk(false);
      setFormNum(formNum - 1);
    } else {
      setFormNum(formNum - 1);
    }
  };
  const gotoNext = () => {
    if (formNum === 1 && (!stipulationAgree[0] || !stipulationAgree[1] || !stipulationAgree[2])) {
      alert("약관에 모두 동의해주셔야 가입이 가능합니다.");
    } else if (formNum === 2) {
      setSubmitOk(true);
    } else {
      if (formNum == 1) {
        setUserRegistIng(undefined);
        setSmatroReigistBilling(undefined);
      }
      setFormNum(formNum + 1);
    }
  };

  useEffect(() => {
    if (location.search) {
      var search = location.search.slice(1)
      var queryParams = search.split('&');
      for (var q in queryParams) {
        var l = queryParams[q].split('=')
        var key = l[0]
        var value = l.slice(1).join('=')
        if (key == 'fn') {
          setFormNum(Number(value))
        } else if (key == 'result') {
          var decodeUrl = decodeURI(value)
          var jsonResult = JSON.parse(decodeUrl)
          setSmatroReigistBilling(
            jsonResult
          )
        }
      }
    }
  }, [location]);
  return (
    <PageLayout>
      <Font fontSize="1.75rem" fontWeight="500" color={cardTitleColor} changeFontSize="20px">
        멤버십 가입
      </Font>
      <Blank height="20px"></Blank>
      <Div className="flex p-[10px]">
        <Tab
          marginLeft="1px"
          borderColor={formNum === 1 ? spanBlueColor : undefined}
          zindex={formNum === 1 ? "2" : undefined}
          fontColor={formNum === 1 ? spanBlueColor : undefined}
          className="rounded-l-[30px]"
        >
          약관동의
        </Tab>
        <Tab
          marginLeft="-1px"
          marginRight="-1px"
          borderColor={formNum === 2 ? spanBlueColor : undefined}
          zindex={formNum === 2 ? "2" : undefined}
          fontColor={formNum === 2 ? spanBlueColor : undefined}
        >
          회원정보입력
        </Tab>
        <Tab
          marginRight="-1px"
          borderColor={formNum === 3 ? spanBlueColor : undefined}
          zindex={formNum === 3 ? "2" : undefined}
          fontColor={formNum === 3 ? spanBlueColor : undefined}
          className="rounded-r-[30px]"
        >
          가입완료
        </Tab>
      </Div>
      <Form onSubmit={memberHandleSubmit(onRegistSubmit)} className="w-full" onKeyDown={checkKeyDown}>
        {(() => {
          switch (formNum) {
            case 1:
              return <StipulationList stipulationAgree={stipulationAgree} setStipulationAgree={setStipulationAgree} />;
            case 2:
              return (
                <MemberRegisterForm
                  register={memberRegister}
                  errors={memberErrors}
                  setValue={memberSetValue}
                  getValues={memberGetValues}
                  carCompanys={getCarCompanys}
                />
              );
            case 3:
              return <div></div>;
            default:
              return null;
          }
        })()}
        <ArrowBox className="pt-[40px] pb-[40px] items-end">
          <Div className="flex justify-between w-[100%]">
            <PageMoveBtn onClick={() => gotoPrev()} type="button">
              <Arrow arrowImg={arrowPrev}></Arrow>이전
            </PageMoveBtn>
            {formNum === 1 || !isSubmitOk ? (
              <PageMoveBtn color={primaryColor} onClick={() => gotoNext()} type="button">
                다음<Arrow arrowImg={arrowNextActive}></Arrow>
              </PageMoveBtn>
            ) : (
              <PageMoveBtn color={primaryColor} type="submit">
                다음<Arrow arrowImg={arrowNextActive}></Arrow>
              </PageMoveBtn>
            )}
          </Div>
        </ArrowBox>
      </Form>
      {/* <EvModal type={modalType} /> */}
      <EvModal type={modalType} setValue={memberSetValue} />
      <Loading isLoading={isLoading} />
    </PageLayout>
  );
}

export default React.memo(RegisterMemberPage);
