import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist';
import { User } from "../model/user";
const { persistAtom } = recoilPersist();

export const errorState = atom({
  key: "error",
  default: "",
});

export const loadingState = atom({
  key: "loading",
  default: false,
});

export const loginState = atom({
  key: "login",
  default: false,
});

export const modalState = atom({
  key: "open",
  default: false,
});

export const isPopupOpenState = atom({
  key: "isPopupOpenState",
  default: false,
});

export const isRepayPopupOpenState = atom({
  key: "isRepayPopupOpenState",
  default: true,
});

export const modalTypeState = atom({
  key: "modalType",
  default: "",
});

export const pageState = atom({
  key: "currentPage",
  default: 1,
});

export const searchState = atom({
  key: "search",
  default: "",
});

export const variableState = atom({
  key: "variable",
  default: undefined,
});
export const variableSecondState = atom({
  key: "variableSecond",
  default: undefined,
});

export const accessTokenState = atom<any>({
  key: "accessToken",
  default: undefined,
});

export const refreshTokenState = atom<any>({
  key: "refreshToken",
  default: undefined,
});
export const noticeDetailState = atom({
  key: "noticeDetail",
  default: undefined,
});

export const noticeMainState = atom({
  key: "noticeMain",
  default: undefined,
});

export const popUpState = atom({
  key: "popupState",
  default: undefined,
});

export const noticeState = atom({
  key: "notice",
  default: undefined,
});

export const findIdState = atom({
  key: "findId",
  default: undefined,
});

export const carCompanyState = atom({
  key: "carCompanyState",
  default: undefined,
});

export const carState = atom({
  key: "carState",
  default: undefined,
});

export const showUserState = atom({
  key: "showUserSate",
  default: undefined,
});

export const userInfoState = atom<User | undefined>({
  key: "userInfoState",
  default: undefined,
});

export const chargingState = atom({
  key: "chargingState",
  default: undefined,
});

export const platformState = atom({
  key: "platformState",
  default: undefined,
});

export const certifyPhoneState = atom({
  key: "certifyPhoneState",
  default: undefined,
});

export const checkAuthState = atom({
  key: "checkAuthState",
  default: undefined,
});

export const userRegistState = atom({
  key: "userRegistState",
  default: undefined,
});

export const companyUserRegistState = atom({
  key: "companyUserRegistState",
  default: undefined,
});

export const findPwState = atom({
  key: "findPwState",
  default: undefined,
});

export const smartroState = atom({
  key: "smartroState",
  default: undefined,
});

export const smartroRoamingState = atom({
  key: "smartroRoamingState",
  default: undefined,
});

export const userRegistIngState = atom({
  key: "userRegistIngState",
  default: undefined as any,
  effects_UNSTABLE: [persistAtom],
});

export const smatroReigistBillingState = atom({
  key: "smatroReigistBillingState",
  default: undefined,
});

export const userChargingState = atom({
  key: "userChargingState",
  default: undefined
})

interface UserPoint {
  membership_card_number?: string;
}

export const userPointState = atom<UserPoint | undefined>({
  key: "userPointState",
  default: undefined,
});

export const userPointListState = atom({
  key: "userListPointState",
  default: undefined,
})

export const userExpiresState = atom({
  key: "userExpiresState",
  default: undefined,
})

export const userMembershipGrade = atom({
  key: "userMembershipGrade",
  default: undefined,
})

export const justMembershipGrade = atom({
  key: "justMembershipGrade",
  default: undefined,
})

export const userRepayState = atom({
  key: "userRepayState",
  default: undefined,
})

export const userPaidFailState = atom({
  key: "userPaidFailState",
  default: undefined,
})