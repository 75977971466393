/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Blank, PageLayout } from "../../styles/commonStyle";
import { getJustMembershipGradeSelector, getUserMembershipGradeSelector, showUserSelector, userExpiresSelector, userPointListSelector, userPointSelector } from "../../recoil/variableAtom";
import { RESULT, ResponseModel } from "../../model/response";
import { useEffect, useLayoutEffect, useState } from "react";
import EvModal from "../../components/modal";
import { getUserIdFromToken } from "../../util";
import { Link, Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import MembershipInformation from "../../components/information";
import PointInformation from "./components/pointInfo";
import Progress from "./progress";
import TopButton from "../../components/topButton/topButton";
import useFetch from "../../hooks/useFetch";
import { User } from "../../model/user";
import UserInfoTab from "./components/userInfoTab";
import { user_info_tab } from "./components/userInfoBase";
import ChargingHistoryPage from "./charging";
import PointHistoryPage from "./pointList";
import { userInfoState } from "../../recoil/atoms";
import { useRecoilState } from "recoil";

function ChargingPage() {
    const [userCardNumber, setUserCardNumber] = useState<any>();
    const [userPoint, setUserPoint] = useState<any>();
    const [totalMileage, setTotalMileage] = useState<number>();
    const [userGradeInfo, setUserGradeInfo] = useState<any>();
    const [expiresPoint, setExpiresPoint] = useState<any>();
    const [membershipInfoList, setMembershipInfoList] = useState<any>();
    const [userInfo, setUserInfo] = useRecoilState(userInfoState);
    let userId = getUserIdFromToken();

    // 멤버십 리스트 요청
    const getJustMembershipGrade: ResponseModel<any> = useFetch({
        recoilSelector: getJustMembershipGradeSelector,
    });

    // 유저 멤버십 카드 요청
    let accessToken = window.sessionStorage.getItem("accessToken");
    const getUserCardNumber: ResponseModel<User> = useFetch({
        recoilSelector: showUserSelector({ token: accessToken! }),
        query: userId,
    });

    useEffect(() => {
        if (getUserCardNumber?.result === RESULT.OK) {
            setUserInfo(getUserCardNumber.data!);
        }
    }, [getUserCardNumber]);

    // 유저 포인트 요청
    const getUserPoint: ResponseModel<any> = useFetch({
        recoilSelector: userPointSelector,
        query: userCardNumber != undefined ? { membership_card_number: userCardNumber } : undefined,
        setQuery: setUserCardNumber,
    });

    // 소멸 예정 포인트 요청
    const getUserExpiresPoint: ResponseModel<any> = useFetch({
        recoilSelector: userExpiresSelector,
    });

    // 회원 멤버십 등급 조회
    const getUserMembershipGrade: ResponseModel<any> = useFetch({
        recoilSelector: getUserMembershipGradeSelector,
    });

    // 회원 멤버십 등급 조회에 따른 state
    useEffect(() => {
        if (getUserMembershipGrade) {
            setUserGradeInfo(getUserMembershipGrade.data)
        }
    }, [getUserMembershipGrade]);

    // 소멸 예정 포인트에 따른 state
    useEffect(() => {
        if (getUserExpiresPoint?.result === RESULT.OK) {
            let items = getUserExpiresPoint.data?.items;
            if (items.length !== 0) {
                setExpiresPoint(items[0].total_point);
            } else {
                setExpiresPoint('0');
            }
        }
    }, [getUserExpiresPoint]);

    // 유저 멤버십 카드 결과값에 따른 state
    useEffect(() => {
        if (getUserCardNumber?.result === RESULT.FAIL) {
            setUserCardNumber('Not found User Membership Card Number..');
        }
        if (getUserCardNumber?.result === RESULT.OK) {
            setUserCardNumber(getUserCardNumber.data?.cardData?.cardNo);
        }
    }, [getUserCardNumber]);

    // 유저 포인트 조회 결과값에 따른 state
    useEffect(() => {
        if (getUserPoint?.result === RESULT.FAIL) {
            setUserPoint('');
            setTotalMileage(0);
        }
        if (getUserPoint?.result === RESULT.OK) {
            setUserPoint(getUserPoint?.data?.total_point);
            setTotalMileage(getUserPoint?.data?.total_mileage);
        }
    }, [getUserPoint]);

    // 멤버십 조회 결과값에 따른 state
    useEffect(() => {
        if (getJustMembershipGrade?.result === RESULT.OK) {
            setMembershipInfoList(getJustMembershipGrade.data);
        }
    }, [getJustMembershipGrade])


    return (
        <PageLayout>

            {
                userGradeInfo && membershipInfoList &&
                <>
                    <PointInformation userPoint={userPoint} totalMileage={totalMileage} expiresPoint={expiresPoint} userGradeInfo={userGradeInfo} />
                    <Blank height="4rem" />
                    <Progress userGradeInfo={userGradeInfo} membershipInfoList={membershipInfoList} />
                    <Blank height="8rem" />
                </>
            }
            <UserInfoTab list={user_info_tab} />
            <Blank height="2rem" />
            <Routes>
                <Route path="/" element={<ChargingHistoryPage />}></Route>
                <Route path="/pointlist" element={<PointHistoryPage />}></Route>
            </Routes>

            {

                userGradeInfo && membershipInfoList && <><Blank height="4rem" /><MembershipInformation membershipInfoList={membershipInfoList} /></>
            }
            <Blank height="8rem" />

            <TopButton />
        </PageLayout>
    );
}

function Test1() {
    return (
        <div>TEST1</div>
    )
}
function Test2() {
    return (
        <div>TEST1</div>
    )
}

export default ChargingPage;

